import { Alert, Button, Card, Skeleton, Table } from "antd";
import { useEffect, useState } from "react";

import { NavLink } from "react-router-dom";
import { PlusCircleOutlined } from "@ant-design/icons";
import { useAuth } from "../../contexts/AuthContext"

export default function TutorsList() {

    const { supabase } = useAuth();
    const [tutors, setTutors] = useState<any[]>([]);
    const [error, setError] = useState<any>(null);

    const [loading, setLoading] = useState<boolean>(true);


    useEffect(() => {
        const getTutors = async () => {
            setLoading(true);
            let { data: tutors, error } = await supabase
                .from('tutors')
                .select('*')
                .order('firstname', { ascending: true });
            console.log("👉 ~ getTutors ~ tutors:", tutors);
            setTutors(tutors || []);
            setError(error);
            setLoading(false);
        }
        getTutors();
    }, [supabase])

    if (loading) return <Skeleton active />

    return (
        <>
            {(tutors && error === null) &&
                <Card
                    title="Tutors"
                    extra={<NavLink to="/tutors/add"><Button type="primary"><PlusCircleOutlined /> Add</Button></NavLink>}
                >
                    {tutors.length > 0 ?
                        <Table
                            scroll={{ x: "max-content" }}
                            dataSource={tutors}
                            pagination={{
                                showSizeChanger: tutors.length > 10,
                                pageSizeOptions: ['10', '20', '30', '40', '50'],
                                defaultPageSize: 20
                            }}
                            rowKey="uid"
                            columns={
                                [
                                    {
                                        title: 'Prefix',
                                        dataIndex: 'prefix',
                                        key: 'prefix',
                                    },
                                    {
                                        title: 'Last Name',
                                        dataIndex: 'lastname',
                                        key: 'lastname',
                                    },
                                    {
                                        title: 'First Name',
                                        dataIndex: 'firstname',
                                        key: 'firstname',
                                    },
                                    {
                                        title: 'E-mail',
                                        dataIndex: 'email',
                                        key: 'email',
                                    },
                                    {
                                        title: 'Phone Number',
                                        dataIndex: 'phone',
                                        key: 'phone',
                                    },
                                    {
                                        title: 'Action',
                                        key: 'action',
                                        render: (text, record) => (
                                            <NavLink to={`/tutors/edit/${record.uid}`}>
                                                Edit
                                            </NavLink>
                                        ),
                                    }
                                ]
                            } />
                        :
                        <Alert
                            message="No tutors found"
                            description="Please add a tutor"
                            type="info"
                            showIcon
                        />
                    }
                </Card>
            }
        </>
    )
}
