import { Alert, Button, Card, Form, Input, Skeleton, notification } from 'antd'
import { useEffect, useState } from 'react';

import { useAuth } from '../../contexts/AuthContext';

export default function Account() {
    const [form] = Form.useForm();
    const { supabase } = useAuth();
    const [error, setError] = useState<any>(null);
    const [loading, setLoading] = useState<boolean>(true);

    const [hasProfileComplete, setHasProfileComplete] = useState<boolean>(false);

    useEffect(() => {
        const getUser = async () => {
            setLoading(true);
            const { data, error } = await supabase
                .from('profiles')
                .select('*')
            if (data && data.length > 0) {
                form.setFieldsValue(data[0])
                setHasProfileComplete(true);
            }
            if (error) {
                setError(error)
            }
            setLoading(false)
        }
        getUser()
    }, [supabase, form]);

    const handleFormSubmit = async (values: any) => {

        const { data: { user } } = await supabase.auth.getUser()
        const userId = user?.id;
        if (!userId) {
            setError({ code: "auth/user-not-found", message: "User not found" });
            return;
        };

        if (hasProfileComplete) {
            let { data: profiles, error } = await supabase
                .from('profiles')
                .update([
                    {
                        ...values
                    }
                ])
                .eq('user_id', userId)
            if (!error) {
                if (profiles) {
                    form.setFieldsValue(profiles[0]);
                }

                notification.success({
                    message: 'Account edited successfully',
                    description: `Account of ${values.firstname} edited successfully`
                });
            }
        }
        else {
            let { data: profiles, error } = await supabase
                .from('profiles')
                .insert([
                    {
                        ...values,
                        user_id: userId
                    }
                ])
            if (!error) {
                if (profiles) {
                    form.setFieldsValue(profiles[0]);
                }

                notification.success({
                    message: 'Account added successfully',
                    description: `Account of ${values.firstname} added successfully`
                });
            }
        }


    }

    if (loading) return <Skeleton />

    return (
        <>
            {error && <Alert message={`Error: ${error.code}: ${error.message}`} type="error" />}
            <Card title="Account">
                <Form
                    form={form}
                    onFinish={handleFormSubmit}
                    labelCol={{ span: 8 }}
                    wrapperCol={{ span: 12 }}
                    initialValues={{ remember: true }}
                    autoComplete="off"
                    labelWrap
                >
                    <Form.Item
                        label="First name"
                        name="firstname"
                        rules={[{ required: true, message: 'Please input your first name!' }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label="Last name"
                        name="lastname"
                        rules={[{ required: true, message: 'Please input your last name!' }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label="Account number"
                        name="accountNumber"
                        rules={[{ required: true, message: 'Please input your account number!' }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label="Communication header"
                        name="communicationHeader"
                        rules={[{ required: true, message: 'Please input your communication header!' }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label="Communication signature"
                        name="communicationSignature"
                        rules={[{ required: true, message: 'Please input your signature!' }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                        <Button type="primary" htmlType="submit">
                            Save
                        </Button>
                    </Form.Item>
                </Form>
            </Card>
        </>
    )
}
