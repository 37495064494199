import { Alert, Button, Card, Form, Input, Select, Skeleton, Switch, notification } from 'antd';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { useAuth } from '../../contexts/AuthContext';

export default function AddToVisits() {
    const [form] = Form.useForm();

    const { supabase } = useAuth();
    const [patients, setPatients] = useState<any[]>([]);
    const [visitsType, setVisitsType] = useState<any[]>([]);
    const [error, setError] = useState<any>(null);

    const [loading, setLoading] = useState<boolean>(false);

    const { editId } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        const getPatients = async () => {
            setLoading(true);
            let { data: tutors, error } = await supabase
                .from('patients')
                .select('*');
            setPatients(tutors || []);
            setError(error);
            setLoading(false);
        }
        const getVisitsType = async () => {
            setLoading(true);
            let { data: visitsType, error } = await supabase
                .from('visitsType')
                .select(`
                    *
                `);
            setVisitsType(visitsType || []);
            setError(error);
            setLoading(false);
        }
        getPatients();
        getVisitsType();
    }, [supabase])

    useEffect(() => {
        if (editId) {
            const getVisit = async () => {
                setLoading(true);
                let { data: visits, error } = await supabase
                    .from('visits')
                    .select(`
                        *,
                        patients: patientsUid (
                            firstname,
                            lastname
                        ),
                        visitsType: visitsTypeUid (
                            name,amount
                        )
                    `)
                    .eq('uid', editId)
                if (visits && visits?.length > 0) {
                    form.setFieldsValue(visits[0]);
                }
                setError(error);
                setLoading(false);
            }
            getVisit();
        }
    }, [editId, form, supabase]);


    const handleFormSubmit = async (values: any) => {
        const { data: { user } } = await supabase.auth.getUser()
        const userId = user?.id;
        if (!userId) {
            setError({ code: "auth/user-not-found", message: "User not found" });
            return;
        };

        if (editId) {
            setLoading(true);
            const { error } = await supabase
                .from('visits')
                .update([
                    {
                        date: values?.date,
                        patientsUid: values?.patientsUid,
                        visitsTypeUid: values?.visitsTypeUid,
                        comment: values?.comment?.trim(),
                        paymentRequested: values?.paymentRequested,
                        paid: values?.paid,
                        user_id: userId
                    },
                ])
                .eq('uid', editId)
            setError(error);
            setLoading(false);
            if (!error) {
                notification.success({
                    message: 'Visit edited successfully',
                    description: `Visit of ${values.date} edited successfully`
                });
                form.resetFields();
                navigate('/visits');

            }
            return;
        } else {
            setLoading(true);
            const { error } = await supabase
                .from('visits')
                .insert([
                    {
                        date: values?.date,
                        patientsUid: values?.patientsUid,
                        visitsTypeUid: values?.visitsTypeUid,
                        comment: values?.comment?.trim(),
                        paymentRequested: values?.paymentRequested,
                        paid: values?.paid,
                        user_id: userId
                    },
                ])
                .select()
            setError(error);
            setLoading(false);
            if (!error) {
                notification.success({
                    message: 'Visit added successfully',
                    description: `Visit of ${values.date} added successfully`
                });
                form.resetFields();
                navigate('/visits');
            }
        }
    }
    if (loading) return <Skeleton active />

    return (
        <>
            {error && <Alert message={`Error: ${error.code}: ${error.message}`} type="error" />}
            {(patients && error === null) &&
                <Card title="Add to Visits">
                    <Form
                        form={form}
                        onFinish={handleFormSubmit}
                        labelCol={{ span: 8 }}
                        wrapperCol={{ span: 12 }}
                        initialValues={{ remember: true }}
                        autoComplete="off"
                        labelWrap
                    >
                        <Form.Item
                            label="Patient"
                            name="patientsUid"
                            rules={[{ required: true, message: 'Please input your patient!' }]}
                        >
                            <Select showSearch>
                                {patients.sort((a, b) => a.firstname.localeCompare(b.firstname)).map(patient => <Select.Option key={patient.uid} value={patient.uid}>{patient.firstname} {patient.lastname}</Select.Option>)}
                            </Select>
                        </Form.Item>
                        <Form.Item
                            label="Visit Type"
                            name="visitsTypeUid"
                            rules={[{ required: true, message: 'Please input your visit type!' }]}
                        >
                            <Select>
                                {visitsType.map(visitType => <Select.Option key={visitType.uid} value={visitType.uid}>{visitType.name} ({visitType.amount})</Select.Option>)}
                            </Select>
                        </Form.Item>
                        <Form.Item
                            label="Date"
                            name="date"
                            rules={[{ required: true, message: 'Please input your date!' }]}
                        >
                            <Input type="date" />
                        </Form.Item>
                        <Form.Item
                            label="Comment"
                            name="comment"
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label="Payment requested"
                            name="paymentRequested"
                            rules={[{ required: true, message: 'Please input your payment requested!' }]}
                            initialValue={false}
                        >
                            <Switch />
                        </Form.Item>
                        <Form.Item
                            label="Paid"
                            name="paid"
                            rules={[{ required: true, message: 'Please input your paid!' }]}
                            initialValue={false}
                        >
                            <Switch />
                        </Form.Item>
                        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                            <Button type="primary" htmlType="submit">
                                {editId ? "Save" : "Add"}
                            </Button>
                        </Form.Item>
                    </Form>
                </Card >
            }
        </>

    )
}
