import "./../../assets/scss/Login.scss";

import { Alert, Button, Card, Flex, Form, Input } from 'antd';

import { useAuth } from "../../contexts/AuthContext";
import { useState } from "react";

const Login = () => {
    const [error, setError] = useState('');
    const { supabase } = useAuth();

    const [form] = Form.useForm();

    const handleForm = async (values: any) => {
        const email: string = values.email;
        const password: string = values.password;
        const { error } = await supabase.auth.signInWithPassword({ email, password });
        if (error) setError(error.message);
    };

    return (
        <section className="loginForm">
            <div className="containerLogin">
                <Flex vertical gap="middle">
                    {error && <Alert type="error" message={error} showIcon />}
                    <Card title="Login">
                        <Form
                            id="login"
                            labelCol={{ span: 8 }}
                            wrapperCol={{ span: 12 }}
                            initialValues={{ remember: true }}
                            autoComplete="off"
                            labelWrap
                            form={form}
                            onFinish={handleForm}
                        >
                            <Form.Item
                                label="E-mail"
                                name="email"
                                rules={[{ required: true, message: 'Please input your email!' }]}
                            >
                                <Input type="email" />
                            </Form.Item>
                            <Form.Item
                                label="Password"
                                name="password"
                                rules={[{ required: true, message: 'Please input your password!' }]}
                            >
                                <Input.Password />
                            </Form.Item>
                            <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                                <Button type="primary" htmlType="submit">
                                    Log in
                                </Button>
                            </Form.Item>

                        </Form>
                    </Card>
                </Flex>
            </div>
        </section>
    );
};

export default Login;
