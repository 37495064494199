import './assets/scss/App.scss';

import { AuthProvider, useAuth } from './contexts/AuthContext';
import { ConfigProvider, notification } from 'antd';

import AnimatedRoutes from './components/layout/AnimatedRoutes';
import Login from './components/auth/Login';

function AppContent() {
    const { session } = useAuth();

    notification.config({
        placement: 'topRight',
        showProgress: true,
    });

    if (!session) {
        return (<Login />);
    } else {
        return (
            <AnimatedRoutes />
        );
    }
}

export default function App() {
    return (
        <AuthProvider>
            <ConfigProvider
                componentSize="middle"
                theme={
                    {
                        components: {
                            Button: {
                                // primaryShadow: "0 0 0 0 rgba(0,0,0,0.2)"
                            }
                        },
                        token: {
                            // colorPrimary: '#1890ff'
                            // colorBgBase: "#eeeeee",

                        }
                    }
                }
            >
                <AppContent />
            </ConfigProvider>
        </AuthProvider>
    );
}