import {
    Route,
    Routes,
    useLocation,
} from "react-router-dom";

import Account from './../account/Account';
import AddToPatients from './../patients/AddToPatients';
import AddToTutors from './../tutors/AddToTutors';
import AddToVisits from './../visits/AddToVisits';
import Dashboard from './../Dashboard';
import Layout from './../layout/Layout';
import PatientsList from './../patients/PatientsList';
import Payments from './../payments/Payments';
import RelationsList from './../relations/ReleationsList';
import { Result } from 'antd';
import TutorsList from './../tutors/TutorsList';
import VisitTypesList from './../visitsType/VisitTypesList';
import VisitsList from './../visits/VisitsList';

export default function AnimatedRoutes() {

    const location = useLocation();

    return (
        <Routes location={location} key={location.pathname}>
            <Route path="/" element={<Layout />}>
                <Route index element={<Dashboard />} />
                <Route path="account" element={<Account />} />
                <Route path="visits">
                    <Route index element={<VisitsList />} />
                    <Route path="add" element={<AddToVisits />} />
                    <Route path="edit/:editId" element={<AddToVisits />} />
                </Route>
                <Route path="patients">
                    <Route index element={<PatientsList />} />
                    <Route path="add" element={<AddToPatients />} />
                    <Route path="edit/:editId" element={<AddToPatients />} />
                </Route>
                <Route path="tutors">
                    <Route index element={<TutorsList />} />
                    <Route path="add" element={<AddToTutors />} />
                    <Route path="edit/:editId" element={<AddToTutors />} />
                </Route>
                <Route path="visitsType" element={<VisitTypesList />} />
                <Route path="payments" element={<Payments />} />
                <Route path="relations">
                    <Route index element={<RelationsList />} />
                </Route>
                <Route path="*" element={<Result status="404" title="404" subTitle="Sorry, the page you visited does not exist." />} />
            </Route>
        </Routes>
    )
}
