import { Alert, Card, Skeleton, Table } from "antd";
import { useEffect, useState } from "react";

import { useAuth } from "../../contexts/AuthContext";

export default function RelationsList() {

    const { supabase } = useAuth();
    const [relations, setRelations] = useState<any[]>([]);
    const [error, setError] = useState<any>(null);

    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        const getRelations = async () => {
            setLoading(true);
            let { data: tutors, error } = await supabase
                .from('relations')
                .select('*')
                .order('type', { ascending: true });
            setRelations(tutors || []);
            setError(error);
            setLoading(false);
        }
        getRelations();
    }, [supabase])

    if (loading) return <Skeleton active />

    return (
        <>
            {(relations && error === null) &&
                <Card
                    title="Relations"
                >
                    {relations.length > 0 ?
                        <Table
                            scroll={{ x: "max-content" }}
                            dataSource={relations}
                            pagination={{
                                showSizeChanger: relations.length > 10,
                                pageSizeOptions: ['10', '20', '30', '40', '50'],
                                defaultPageSize: 20
                            }}
                            rowKey="uid"
                            columns={
                                [
                                    {
                                        title: 'Type',
                                        dataIndex: 'type',
                                        key: 'type',
                                        render: (text: string) => {
                                            return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
                                        }
                                    }
                                ]
                            } />
                        :
                        <Alert
                            message="No relations found"
                            type="info"
                            showIcon
                        />
                    }
                </Card>
            }
        </>
    )
}
