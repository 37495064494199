import { Alert, Button, Card, Skeleton, Table, Tag } from "antd";
import { useEffect, useState } from "react";

import { NavLink } from "react-router-dom";
import { PlusCircleOutlined } from "@ant-design/icons";
import { useAuth } from "../../contexts/AuthContext"

export default function VisitsList() {

    const { supabase } = useAuth();
    const [visits, setVisits] = useState<any[]>([]);
    const [error, setError] = useState<any>(null);

    const [loading, setLoading] = useState<boolean>(true);


    useEffect(() => {
        const getVisits = async () => {
            setLoading(true);
            let { data: visits, error } = await supabase
                .from('visits')
                .select(`
                    *,
                    patients: patientsUid (
                        firstname,
                        lastname
                    ),
                    visitsType: visitsTypeUid (
                        name,amount
                    )
                `)
                .order('date', { ascending: false });
            setVisits(visits || []);
            setError(error);
            setLoading(false);
        }
        getVisits();
    }, [supabase])

    const getDistinctPatientsName = (visits: any[]) => {
        const patients:string[] = visits.map(visit => visit.patients.lastname + ' ' + visit.patients.firstname);
        const removeDuplicates:string[] = Array.from(new Set(patients));
        return removeDuplicates.sort((a, b) => a.localeCompare(b));
    }

    if (loading) return <Skeleton active />

    return (
        <>

            {(visits && error === null) &&
                <Card
                    title="Visits"
                    extra={<NavLink to="/visits/add"><Button type="primary"><PlusCircleOutlined /> Add</Button></NavLink>}
                >
                    {visits.length > 0 ?
                        <Table
                            scroll={{ x: "max-content" }}
                            dataSource={visits}
                            pagination={{
                                showSizeChanger: visits.length > 10,
                                pageSizeOptions: ['10', '20', '30', '40', '50'],
                                defaultPageSize: 20
                            }}
                            rowKey="uid"
                            columns={
                                [
                                    {
                                        title: 'Date',
                                        dataIndex: 'date',
                                        key: 'date',
                                        sorter: (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime(),
                                    },
                                    {
                                        title: 'Type',
                                        dataIndex: 'visitsType',
                                        key: 'visitsType',
                                        render: (visitsType) => visitsType?.name + ' (' + visitsType?.amount + ')',
                                        sorter: (a, b) => a.visitsType.name.localeCompare(b.visitsType.name),
                                    },
                                    {
                                        title: 'Patient',
                                        dataIndex: 'patients',
                                        key: 'patients',
                                        render: (patients) => patients?.lastname + ' ' + patients?.firstname,
                                        sorter: (a, b) => a.patients.lastname.localeCompare(b.patients.lastname),
                                        filters: getDistinctPatientsName(visits).map(patient => ({ text: patient, value: patient })),
                                        onFilter: (value, record) => record.patients.lastname + ' ' + record.patients.firstname === value,
                                    },
                                    {
                                        title: 'Comments',
                                        dataIndex: 'comment',
                                        key: 'comment',
                                    },
                                    {
                                        title: "Payment requested",
                                        dataIndex: "paymentRequested",
                                        key: "paymentRequested",
                                        render: (paymentRequested) => paymentRequested ? <Tag color="green">Requested</Tag> : <Tag color="red">Not requested</Tag>,
                                        sorter: (a, b) => a.paymentRequested - b.paymentRequested,
                                        onFilter: (value, record) => record.paymentRequested === value,
                                        filters: [
                                            { text: 'Requested', value: true },
                                            { text: 'Not requested', value: false },
                                        ]
                                    },
                                    {
                                        title: 'Paid',
                                        dataIndex: 'paid',
                                        key: 'paid',
                                        render: (paid) => paid ? <Tag color="green">Paid</Tag> : <Tag color="red">Paiement due</Tag>,
                                        sorter: (a, b) => a.paid - b.paid,
                                        onFilter: (value, record) => record.paid === value,
                                        filters: [
                                            { text: 'Paid', value: true },
                                            { text: 'Paiement due', value: false },
                                        ]
                                    },
                                    {
                                        title: 'Action',
                                        key: 'action',
                                        render: (record) => (
                                            <NavLink to={`/visits/edit/${record.uid}`}>Edit</NavLink>
                                        ),
                                    }
                                ]
                            } />
                        :
                        <Alert
                            message="No visits"
                            description="There are no visits to show."
                            type="info"
                            showIcon
                        />
                    }
                </Card>
            }
        </>
    )
}
