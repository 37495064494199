import { CarOutlined, EuroOutlined, ForkOutlined, HomeOutlined, LogoutOutlined, UserAddOutlined, UserOutlined } from '@ant-design/icons';
import { NavLink, useLocation } from 'react-router-dom';

import { Menu } from 'antd';
import type { MenuProps } from 'antd';
import { useAuth } from '../../contexts/AuthContext';
import { useState } from 'react';

type MenuItem = Required<MenuProps>['items'][number];
export default function NavBar() {
    const location = useLocation();
    const pathname = location.pathname.substring(1);
    const [current, setCurrent] = useState<string>(location.pathname);

    const { supabase } = useAuth();
    const handleLogout = async () => {
        await supabase.auth.signOut();
    };


    const onClick: MenuProps['onClick'] = (e) => {
        console.log('click ', e);
        setCurrent(e.key);
    };

    const items: MenuItem[] = [
        {
            label: <NavLink to="/">Dashboard</NavLink>,
            key: '/',
            icon: <HomeOutlined />,
            // link: '/',
        },
        {
            label: <NavLink to="/visits">Visits</NavLink>,
            key: '/visits',
            icon: <CarOutlined />
        },
        {
            label: <NavLink to="/payments">Payments</NavLink>,
            key: '/payments',
            icon: <EuroOutlined />
        },
        {
            label: <NavLink to="/patients">Patients</NavLink>,
            key: '/patients',
            icon: <UserOutlined />
        },
        {
            label: <NavLink to="/tutors">Tutors</NavLink>,
            key: '/tutors',
            icon: <UserAddOutlined />
        },
        {
            label: <NavLink to="/visitsType">Visits Type</NavLink>,
            key: '/visitsType',
            icon: <CarOutlined />
        },
        {
            label: <NavLink to="/relations">Relations</NavLink>,
            key: '/relations',
            icon: <ForkOutlined />
        },
        {
            label: <NavLink to="/account">Account</NavLink>,
            key: '/account',
            icon: <UserOutlined />
        },
        {
            key: 'logout',
            icon: <LogoutOutlined />,
            label: (
                <span onClick={handleLogout}>Logout</span>
            ),
        },
    ];


    return <Menu onClick={onClick} selectedKeys={[current]} mode="horizontal" items={items} defaultSelectedKeys={[pathname]} />;
}
