import { Alert, Card, Skeleton, Table } from "antd";
import { useEffect, useState } from "react";

import { useAuth } from "../../contexts/AuthContext"

export default function VisitTypesList() {

    const { supabase } = useAuth();
    const [visitsType, setVisitsType] = useState<any[]>([]);
    const [error, setError] = useState<any>(null);

    const [loading, setLoading] = useState<boolean>(true);


    useEffect(() => {
        const getVisitsType = async () => {
            setLoading(true);
            let { data: visitsType, error } = await supabase
                .from('visitsType')
                .select(`
                    *
                `);
            setVisitsType(visitsType || []);
            setError(error);
            setLoading(false);
        }
        getVisitsType();
    }, [supabase])

    if (loading) return <Skeleton active />

    return (
        <>
            {(visitsType && error === null) &&
                <Card title="Visits Type">
                    {visitsType.length > 0 ?
                        <Table
                            scroll={{ x: "max-content" }}
                            dataSource={visitsType}
                            pagination={{

                                showSizeChanger: visitsType.length > 10,
                                pageSizeOptions: ['10', '20', '30', '40', '50'],
                                defaultPageSize: 20
                            }}
                            rowKey="uid"
                            columns={
                                [
                                    {
                                        title: 'Type',
                                        dataIndex: 'name',
                                        key: 'name'
                                    },
                                    {
                                        title: 'Amount',
                                        dataIndex: 'amount',
                                        key: 'amount',
                                        sorter: (a: any, b: any) => a.amount - b.amount,
                                    },
                                ]
                            } />
                        :
                        <Alert
                            message="No Visits Type"
                            description="Please add a visit type."
                            type="info"
                            showIcon
                        />
                    }
                </Card>
            }
        </>
    )
}
