import "./../../assets/scss/Layout.scss";

import { Flex } from "antd";
import NavBar from "./NavBar";
import { Outlet } from 'react-router-dom';
import { motion } from "framer-motion";

export default function Layout() {
    return (
        <main className="layout">
            <Flex vertical gap={"middle"}>
                <NavBar />
                <motion.div
                    initial={{ opacity: 0, y: 100 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: -100 }}
                    transition={{ duration: .5 }}
                    className="content"
                >

                    <Outlet />
                </motion.div>
            </Flex>
        </main>
    )
}