import { Alert, Card, Col, Flex, Row, Skeleton, Statistic } from "antd";
import { CarOutlined, UserAddOutlined, UserOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";

import { NavLink } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";

export default function Dashboard() {

    const { supabase } = useAuth();

    const [visits, setVisits] = useState<any[]>([]);
    const [patients, setPatients] = useState<any[]>([]);
    const [tutors, setTutors] = useState<any[]>([]);
    const [error, setError] = useState<any>(null);

    const [loading, setLoading] = useState<boolean>(true);

    const [profile, setProfile] = useState<any>(null);

    const [hasProfileComplete, setHasProfileComplete] = useState<boolean>(false);

    useEffect(() => {
        const getVisits = async () => {
            setLoading(true);
            let { data: visits, error } = await supabase
                .from('visits')
                .select(`*,
                    visitsType: visitsTypeUid (
                        name,amount
                    )`)
            setVisits(visits || []);
            setError(error);
            setLoading(false);
        }
        const getPatients = async () => {
            setLoading(true);
            let { data: patients, error } = await supabase
                .from('patients')
                .select(`*`);
            setPatients(patients || []);
            setError(error);
            setLoading(false);
        }
        const getTutors = async () => {
            setLoading(true);
            let { data: tutors, error } = await supabase
                .from('tutors')
                .select(`*`);
            setTutors(tutors || []);
            setError(error);
            setLoading(false);
        }
        const getUser = async () => {
            setLoading(true);
            const { data, error } = await supabase
                .from('profiles')
                .select('*')
            if (data && data.length > 0) {
                setProfile(data[0]);
                setHasProfileComplete(true);
            }
            if (error) {
                setError(error)
            }
            setLoading(false)
        }

        getVisits();
        getPatients();
        getTutors();
        getUser();
    }, [supabase])

    const getTotalAmountPaymentRequested = (visits: any) => {
        const unRequestedVisits = visits.filter((v: any) => !v.paymentRequested);
        let count: number = 0;
        unRequestedVisits.forEach((v: any) => {
            count += v.visitsType.amount;
        });
        return Math.round(count * 100) / 100;
    }

    const getTotalAmountPaymentDue = (visits: any) => {
        const unRequestedVisits = visits.filter((v: any) => !v.paid);
        let count: number = 0;
        unRequestedVisits.forEach((v: any) => {
            count += v.visitsType.amount;
        });
        return Math.round(count * 100) / 100;
    }

    if (loading) return <Skeleton active />

    return (
        <section>
            {error && <Alert message={`Error: ${error.code}: ${error.message}`} type="error" />}
            <Flex vertical gap="middle">
                {!hasProfileComplete && <Alert message={<>Please complete your profile via <NavLink to="/account">the Account page</NavLink></>} type="warning" showIcon />}
                {profile &&
                    <Row gutter={[16, 16]}>
                        <Col md={24} sm={24} xs={24}>
                            <Card>
                                Welcome back {profile.firstname} {profile.lastname}
                            </Card>
                        </Col>
                    </Row>
                }
                <Row gutter={[16, 16]}>
                    <Col md={12} sm={24} xs={24}>
                        <Card bordered={false}>
                            <Statistic
                                title={visits.filter((v: any) => !v.paymentRequested).length > 0 ? `Payments to request` : "Payments up to date"}
                                value={(visits.filter((v: any) => !v.paymentRequested).length > 0) ? visits.filter((v: any) => !v.paymentRequested).length : "All requested"}
                                valueStyle={{ color: visits.filter((v: any) => !v.paymentRequested).length > 0 ? '#cf1322' : '#3f8600' }}
                            />
                        </Card>
                    </Col>
                    <Col md={12} sm={24} xs={24}>
                        <Card bordered={false}>
                            <Statistic
                                title={"Amount to request"}
                                value={getTotalAmountPaymentRequested(visits) + " €"}
                                valueStyle={{ color: getTotalAmountPaymentRequested(visits) > 0 ? '#cf1322' : '#3f8600' }}
                            />
                        </Card>
                    </Col>
                </Row>
                <Row gutter={[16, 16]}>
                    <Col md={12} sm={24} xs={24}>
                        <Card bordered={false}>
                            <Statistic
                                title={visits.filter((v: any) => !v.paid).length > 0 ? "Payments due" : "Payments up to date"}
                                value={(visits.filter((v: any) => !v.paid).length > 0) ? visits.filter((v: any) => !v.paid).length : "All paid"}
                                valueStyle={{ color: visits.filter((v: any) => !v.paid).length > 0 ? '#cf1322' : '#3f8600' }}
                            />
                        </Card>
                    </Col>
                    <Col md={12} sm={24} xs={24}>
                        <Card bordered={false}>
                            <Statistic
                                title={"Amount due"}
                                value={getTotalAmountPaymentDue(visits) + " €"}
                                valueStyle={{ color: getTotalAmountPaymentDue(visits) > 0 ? '#cf1322' : '#3f8600' }}
                            />
                        </Card>
                    </Col>
                </Row>
                <Row gutter={[16, 16]}>
                    <Col md={8} sm={24} xs={24}>
                        <Card bordered={false}>
                            <Statistic
                                title="Visits"
                                value={visits.length}
                                valueStyle={{ color: '#3f8600' }}
                                prefix={<CarOutlined />}
                            />
                        </Card>
                    </Col>
                    <Col md={8} sm={24} xs={24}>
                        <Card bordered={false}>
                            <Statistic
                                title="Patients"
                                value={patients.length}
                                valueStyle={{ color: '#3f8600' }}
                                prefix={<UserOutlined />}
                            />
                        </Card>
                    </Col>
                    <Col md={8} sm={24} xs={24}>
                        <Card bordered={false}>
                            <Statistic
                                title="Tutors"
                                value={tutors.length}
                                valueStyle={{ color: '#3f8600' }}
                                prefix={<UserAddOutlined />}
                            />
                        </Card>
                    </Col>
                </Row>
            </Flex>

        </section>
    )
}
