import { ReactNode, createContext, useContext, useEffect, useState } from 'react';
import { Session, SupabaseClient, createClient } from '@supabase/supabase-js';

const supabaseUrl = 'https://drckiirvbxqphrimacwp.supabase.co';
const supabaseKey = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImRyY2tpaXJ2YnhxcGhyaW1hY3dwIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MzIxODkxNTIsImV4cCI6MjA0Nzc2NTE1Mn0.Uf4H0nTfCT0Vec17cGOgosipztFnCqGARqi7yZD4eho';
const supabase: SupabaseClient = createClient(supabaseUrl, supabaseKey);

interface AuthContextProps {
    session: Session | null;
    supabase: SupabaseClient;
}

const AuthContext = createContext<AuthContextProps | undefined>(undefined);

export const AuthProvider = ({ children }: { children: ReactNode }) => {
    const [session, setSession] = useState<Session | null>(null);

    useEffect(() => {
        supabase.auth.getSession().then(({ data: { session } }) => {
            setSession(session);
        });

        const { data: { subscription } } = supabase.auth.onAuthStateChange((_event, session) => {
            setSession(session);
        });

        return () => {
            subscription.unsubscribe();
        };
    }, []);

    return (
        <AuthContext.Provider value={{ session, supabase }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => {
    const context = useContext(AuthContext);
    if (context === undefined) {
        throw new Error('useAuth must be used within an AuthProvider');
    }
    return context;
};
