import { Alert, Button, Card, Form, Input, Select, Skeleton, notification } from 'antd';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { useAuth } from '../../contexts/AuthContext';

export default function AddToPatients() {
    const [form] = Form.useForm();

    const { supabase } = useAuth();
    const [tutors, setTutors] = useState<any[]>([]);
    const [relations, setRelations] = useState<any[]>([]);
    const [error, setError] = useState<any>(null);

    const [loading, setLoading] = useState<boolean>(false);

    const { editId } = useParams();
    const navigate = useNavigate();


    useEffect(() => {
        const getTutors = async () => {
            setLoading(true);
            let { data: tutors, error } = await supabase
                .from('tutors')
                .select('*');
            setTutors(tutors || []);
            setError(error);
            setLoading(false);
        }
        const getRelations = async () => {
            setLoading(true);
            let { data: tutors, error } = await supabase
                .from('relations')
                .select('*');
            setRelations(tutors || []);
            setError(error);
            setLoading(false);
        }
        getTutors();
        getRelations();
    },[supabase])

    useEffect(() => {
        if (editId) {
            const getPatient = async () => {
                setLoading(true);
                let { data: patients, error } = await supabase
                    .from('patients')
                    .select('*')
                    .eq('uid', editId)
                if (patients && patients?.length > 0) {
                    form.setFieldsValue(patients[0]);
                }
                setError(error);
                setLoading(false);
            }
            getPatient();
        }
    }, [editId, form, supabase]);


    const handleFormSubmit = async (values: any) => {
        const { data: { user } } = await supabase.auth.getUser()
        const userId = user?.id;
        if (!userId) {
            setError({ code: "auth/user-not-found", message: "User not found" });
            return;
        };


        if (editId) {
            setLoading(true);
            const { error } = await supabase
                .from('patients')
                .update([
                    {
                        prefix: values?.prefix?.trim(),
                        firstname: values?.firstname?.trim(),
                        lastname: values?.lastname?.trim(),
                        location: values?.location?.trim(),
                        relationUid: values?.relationUid?.trim(),
                        tutorsUid: values?.tutorsUid?.trim(),
                        user_id: userId
                    },
                ])
                .eq('uid', editId)
            setError(error);
            setLoading(false);
            if (!error) {
                notification.success({
                    message: 'Patient edited successfully',
                    description: `Patient ${values?.firstname} ${values?.lastname} edited successfully`
                });
                form.resetFields();
                navigate('/patients');

            }
            return;
        } else {
            setLoading(true);
            const { error } = await supabase
                .from('patients')
                .insert([
                    {
                        prefix: values?.prefix?.trim(),
                        firstname: values?.firstname?.trim(),
                        lastname: values?.lastname?.trim(),
                        location: values?.location?.trim(),
                        relationUid: values?.relationUid?.trim(),
                        tutorsUid: values?.tutorsUid?.trim(),
                        user_id: userId
                    },
                ])
                .select()
            setError(error);
            setLoading(false);
            if (!error) {
                notification.success({
                    message: 'Patient added successfully',
                    description: `Patient ${values?.firstname} ${values?.lastname} added successfully`
                });
                form.resetFields();
                navigate('/patients');
            }
        }
    }

    if (loading) return <Skeleton active />

    return (
        <>
            {error && <Alert message={`Error: ${error.code}: ${error.message}`} type="error" />}
            {(tutors && error === null) &&
                <Card title="Add to Patients">
                    <Form
                        form={form}
                        onFinish={handleFormSubmit}
                        labelCol={{ span: 8 }}
                        wrapperCol={{ span: 12 }}
                        initialValues={{ remember: true }}
                        autoComplete="off"
                        labelWrap
                    >
                        <Form.Item
                            label="Prefix"
                            name="prefix"
                            rules={[{ required: true, message: 'Please input your prefix!' }]}
                        >
                            <Select showSearch>
                                <Select.Option value="Monsieur">Monsieur</Select.Option>
                                <Select.Option value="Madame">Madame</Select.Option>
                                <Select.Option value="Mademoiselle">Mademoiselle</Select.Option>
                            </Select>
                        </Form.Item>
                        <Form.Item
                            label="First Name"
                            name="firstname"
                            rules={[{ required: true, message: 'Please input your first name!' }]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label="Last Name"
                            name="lastname"
                            rules={[{ required: true, message: 'Please input your last name!' }]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label="Location"
                            name="location"
                            rules={[{ required: true, message: 'Please input your location!' }]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label="Tutor"
                            name="tutorsUid"
                            rules={[{ required: true, message: 'Please input your tutor!' }]}
                        >
                            <Select>
                                {tutors.sort((a, b) => a.firstname.localeCompare(b.firstname)).map(tutor => <Select.Option key={tutor.uid} value={tutor.uid}>{tutor.firstname} {tutor.lastname}</Select.Option>)}
                            </Select>
                        </Form.Item>
                        <Form.Item
                            label="Tutor relation with patient"
                            name="relationUid"
                            rules={[{ required: true, message: 'Please input your relation!' }]}
                        >
                            <Select>
                                {relations.map(relation => <Select.Option key={relation.uid} value={relation.uid}>{relation.type}</Select.Option>)}
                            </Select>
                        </Form.Item>
                        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                            <Button type="primary" htmlType="submit">
                                {editId ? "Save" : "Add" }
                            </Button>
                        </Form.Item>
                    </Form>
                </Card>
            }
        </>

    )
}
