import { Alert, Button, Card, Form, Input, Select, Skeleton, notification } from 'antd';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { useAuth } from '../../contexts/AuthContext';

export default function AddToTutors() {
    const [form] = Form.useForm()
    const { supabase } = useAuth();
    const [error, setError] = useState<any>(null);

    const { editId } = useParams();
    const navigate = useNavigate();

    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        if (editId) {
            setLoading(true);
            const getTutor = async () => {
                let { data: tutors, error } = await supabase
                    .from('tutors')
                    .select('*')
                    .eq('uid', editId)
                if (tutors && tutors?.length > 0) {
                    form.setFieldsValue(tutors[0]);
                }
                setError(error);
                setLoading(false);
            }
            getTutor();
        }
    }, [editId, form, supabase]);

    const handleFormSubmit = async (values: any) => {
        const { data: { user } } = await supabase.auth.getUser()
        const userId = user?.id;
        if(!userId) {
            setError({code:"auth/user-not-found", message:"User not found"});
            return;
        };

        if (editId) {
                setLoading(true);
            const { error } = await supabase
                .from('tutors')
                .update([
                    {
                        prefix: values?.prefix?.trim(),
                        firstname: values?.firstname?.trim(),
                        lastname: values?.lastname?.trim(),
                        email: values?.email?.trim(),
                        phone: values?.phone?.trim(),
                        user_id: userId
                    },
                ])
                .eq('uid', editId)
            setError(error);
            setLoading(false);
            if (!error) {
                notification.success({
                    message: 'Tutor edited successfully',
                    description: `Tutor ${values?.firstname} ${values?.lastname} edited successfully`
                });
                form.resetFields();
                navigate('/tutors');

            }
            return;
        } else {
            setLoading(true);
            const { error } = await supabase
                .from('tutors')
                .insert([
                    {
                        prefix: values?.prefix?.trim(),
                        firstname: values?.firstname?.trim(),
                        lastname: values?.lastname?.trim(),
                        email: values?.email?.trim(),
                        phone: values?.phone?.trim(),
                        user_id: userId
                    },
                ])
                .select()
            setError(error);
            setLoading(false);
            if (!error) {
                notification.success({
                    message: 'Tutor added successfully',
                    description: `Tutor ${values?.firstname} ${values?.lastname} added successfully`
                });
                form.resetFields();
                navigate('/tutors');
            }
        }
    }
    if(loading) return <Skeleton active />

    return (
        <>
            {error && <Alert message={`Error: ${error.code}: ${error.message}`} type="error" />}
            <Card
                title="Add to Tutors"
            >
                <Form
                    form={form}
                    onFinish={handleFormSubmit}
                    labelCol={{ span: 8 }}
                    wrapperCol={{ span: 12 }}
                    initialValues={{ remember: true }}
                    autoComplete="off"
                    labelWrap
                >
                    <Form.Item
                        label="Prefix"
                        name="prefix"
                        rules={[{ required: true, message: 'Please input your prefix!' }]}
                    >
                        <Select showSearch>
                            <Select.Option value="Monsieur">Monsieur</Select.Option>
                            <Select.Option value="Madame">Madame</Select.Option>
                            <Select.Option value="Mademoiselle">Mademoiselle</Select.Option>
                        </Select>
                    </Form.Item>
                    <Form.Item
                        label="First Name"
                        name="firstname"
                        rules={[{ required: true, message: 'Please input your first name!' }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label="Last Name"
                        name="lastname"
                        rules={[{ required: true, message: 'Please input your last name!' }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label="E-Mail"
                        name="email"
                        rules={[{ required: true, message: 'Please input your e-mail!' }]}
                    >
                        <Input type="email" />
                    </Form.Item>
                    <Form.Item
                        label="Phone Number"
                        name="phone"
                        rules={[{ required: true, message: 'Please input your phone number!' }]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                        <Button type="primary" htmlType="submit">
                            {editId ? "Save" : "Add" }
                        </Button>
                    </Form.Item>
                </Form>
            </Card>

        </>

    )
}
